import { Datasource } from '@/base/services/storyblok';
import type { OptionType } from '@/components/elements/Select/Select.types';
import { FiltersType } from '@/components/sections/FilteringSection/FilteringSection.types';

// case: filtering on url /events (events & live webinars)
export function handleEventsAndLiveWebinars(prevValue: FiltersType, name: string) {
  return {
    ...prevValue,
    [name]: {
      in: 'event,webinar',
    },
  };
}

// case: filtering webinars on url /resources (on-demand webinars)
export function handleonDemandWebinars(prevValue: FiltersType, name: string, selected: OptionType) {
  return {
    ...prevValue,
    [name]: {
      in: selected.value,
    },
    event_type: {
      not_in: 'webinar',
    },
  };
}

// case handleAllResources (live webinars need to be hidden there)
export function handleAllResources(prevValue: FiltersType) {
  return {
    ...prevValue,
    __or: [
      {
        event_type: {
          is: 'null',
        },
      },
      {
        event_type: {
          not_in: 'webinar',
        },
      },
    ],
  };
}

export function getFiltersInitialState(isDataTypeEvents: boolean): FiltersType {
  if (!isDataTypeEvents) return {};
  return {
    event_type: {
      options: [] as Datasource[],
      selected: {
        data_source: 'event_type',
        name: 'All types',
        value: '[all]',
        id: 'storyblokdatasourceentry-1137089-default',
      } as OptionType,
    },
  };
}

export function handleEventsWebinarsPaths(isDataTypeEvents: boolean, language: string) {
  if (!isDataTypeEvents) return;
  return `${language}/events/*,${language}/webinar/*`;
}
