import { FILTERS, FILTER_DATA_TYPE } from '@/components/sections/FilteringSection/FilteringSection.types';

export const INDEX_PAGES_FILTERS = {
  [FILTER_DATA_TYPE.NEWS]: [FILTERS.NEWS],
  [FILTER_DATA_TYPE.CUSTOMERS]: [FILTERS.INDUSTRY, FILTERS.BUSINESS_INITIATIVE],
  [FILTER_DATA_TYPE.RESOURCES]: [FILTERS.TYPE, FILTERS.CATEGORY],
  [FILTER_DATA_TYPE.BLOG]: [FILTERS.CATEGORY],
  [FILTER_DATA_TYPE.EVENTS]: [FILTERS.EVENT],
  [FILTER_DATA_TYPE.JOBS]: [FILTERS.JOBS_DEPARTMENTS, FILTERS.JOBS_LOCATIONS, FILTERS.JOBS_TYPES],
};
