import React, { useState, useEffect, useContext, useMemo } from 'react';
import useFilteringEntrires from '@/components/sections/FilteringSection/useFilteringEntries';
import Select from '@/components/elements/Select';
import PostCard from '@/components/modules/PostCard';
import { postsRelations } from '@/base/consts/relations';
import { FeaturedEntriesContext } from '@/base/context/FeaturedEntries/provider';
import { INDEX_PAGES_FILTERS } from '@/base/consts/filters';
import { Button } from '@/components/elements/Button';
import { LanguageContext } from '@/base/context/Language/provider';
import { ConfigContext } from '@/base/context/Config/provider';
import { Datasource } from '@/base/services/storyblok';
import { createAcceptableResourcesSlugs, resolveLabelBySlug } from '@/base/helpers/strings';
import ModuleWrapper from '@/components/modules/ModuleWrapper/ModuleWrapper';
import { getFiltersInitialState, handleEventsWebinarsPaths } from '@/base/helpers/filteringSection';
import type { OptionType } from '@/components/elements/Select/Select.types';
import type { PostCardTypes } from '@/components/modules/PostCard/PostCard.types';
import { FILTER_DATA_TYPE, FilteringSectionTypes, FiltersType } from './FilteringSection.types';
import * as styles from './FilteringSection.module.scss';

export default function FilteringSection({ data_type, results_per_page, title }: FilteringSectionTypes) {
  const isDataTypeResources = data_type === FILTER_DATA_TYPE.RESOURCES;
  const isDataTypeEvents = data_type === FILTER_DATA_TYPE.EVENTS;

  const filtersInitialState = getFiltersInitialState(isDataTypeEvents);
  const [filters, setFilters] = useState<FiltersType>(filtersInitialState);

  const {
    state: { language },
  } = useContext(LanguageContext);

  const {
    state: { featuredEntries },
  } = useContext(FeaturedEntriesContext);

  const {
    state: { config },
  } = useContext(ConfigContext);

  const hiddenElements =
    isDataTypeResources || isDataTypeEvents
      ? {
          filter_query: {
            hide_from_lists: {
              in: false,
            },
          },
        }
      : {};

  function createAcceptableFullSlugsPaths(options: Datasource[]) {
    if (!options) return '';
    const acceptableSlugs = options.map(({ value }) => createAcceptableResourcesSlugs(language, value));
    return acceptableSlugs.join('');
  }

  function onSelectChange(name: string, value: OptionType) {
    setFilters(prevState => ({ ...prevState, [name]: { ...prevState[name], selected: value } }));
  }

  const { entries, status, loadMoreResults, nextPageAvailable, isLoading } = useFilteringEntrires<PostCardTypes>({
    queryParameters: {
      // @ts-ignore - API INCOMPATIBILITY
      resolve_relations: postsRelations,
      is_startpage: 0,
      excluding_ids: featuredEntries,
      by_slugs:
        createAcceptableFullSlugsPaths(filters?.['type']?.options) ||
        handleEventsWebinarsPaths(isDataTypeEvents, language) ||
        `${language}/${data_type}/*`,
      per_page: results_per_page || 6,
      sort_by: isDataTypeEvents ? 'content.date:asc' : 'first_published_at:desc',
      ...hiddenElements,
    },
    filters,
    isDataTypeEvents,
    isDataTypeResources,
  });

  useEffect(() => {
    INDEX_PAGES_FILTERS[data_type]?.forEach(filter => {
      return import(`~/public/datasources/${filter}.json`).then(module => {
        if (!module.default || !Array.isArray(module.default)) return null;
        setFilters(prevState => {
          const STATE = {
            ...prevState,
            [filter]: { options: module.default, selected: module.default[0] },
          };

          return Object.keys(STATE)
            .sort()
            .reduce((obj, key) => {
              obj[key] = STATE[key];
              return obj;
            }, {});
        });
      });
    });
  }, [data_type, featuredEntries]);

  const filltersValueInitial = useMemo(() => {
    const keys = Object.keys(filters);
    const values = keys.map(key => filters[key].selected.value);
    return values.every(selectedValue => selectedValue === '[all]');
  }, [filters]);

  if ((featuredEntries.split(',').length < 5 && isDataTypeEvents) || (filltersValueInitial && !entries.length))
    return null;

  return (
    <ModuleWrapper topSpacing="none" bottomSpacing="md" mobileTopSpacing="none" outsideSpacing={false}>
      {filters && (
        <ul className={styles.selectsList}>
          {Object.entries(filters).map(([name, { options, selected }]) => {
            return (
              <li key={name} className="p-0">
                <Select options={options} name={name} selected={selected} onChange={onSelectChange} />
              </li>
            );
          })}
        </ul>
      )}
      {title && <h2 className={styles.title}>{title}</h2>}
      {Array.isArray(entries) && entries.length ? (
        <ul className={styles.cards}>
          {entries.map((card, index) => (
            <PostCard
              key={card.uuid}
              {...card}
              label={card?.content.card_link_label || resolveLabelBySlug(card.full_slug, config)}
              position={index}
            />
          ))}
        </ul>
      ) : (
        <div>
          {!!entries.length && status === 'fulfilled' && (
            <h3 className={styles.notFoundMessage}>{config?.no_posts_message}</h3>
          )}
        </div>
      )}
      {nextPageAvailable && (
        <div className={styles.buttonWrapper}>
          <Button variant="primary" onClick={loadMoreResults} className={styles.button} disabled={isLoading}>
            {isLoading ? (
              <div className={styles.loader} role="status" aria-live="polite">
                <span className={styles.screenReaderOnly}>Loading...</span>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              config?.load_more_label
            )}
          </Button>
        </div>
      )}
    </ModuleWrapper>
  );
}
