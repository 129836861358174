import { useState, useEffect, useContext } from 'react';
import { StoriesParams } from 'storyblok-js-client';
import { FILTERS, FiltersType } from '@/components/sections/FilteringSection/FilteringSection.types';
import { LanguageContext } from '@/base/context/Language/provider';
import { getStories } from '@/base/services/storyblok';
import { assignType } from '@/base/helpers/types';
import {
  handleAllResources,
  handleEventsAndLiveWebinars,
  handleonDemandWebinars,
} from '@/base/helpers/filteringSection';

type UseFilteringEntriresTypes = {
  queryParameters: StoriesParams;
  filters?: FiltersType | { category?: undefined };
  isDataTypeEvents: boolean;
  isDataTypeResources: boolean;
};

export default function useFilteringEntrires<T>({
  queryParameters,
  filters,
  isDataTypeEvents,
  isDataTypeResources,
}: UseFilteringEntriresTypes) {
  const {
    state: { language },
  } = useContext(LanguageContext);

  const [entries, setEntries] = useState<StoryblokEntry<T>[] | { [key: string]: StoryblokEntry<T>[] }>([]);
  const [status, setStatus] = useState<'pending' | 'fulfilled'>('pending');
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState<{
    current: number;
    total: number;
  }>({ current: 1, total: 1 });
  const [nextPageAvailable, setNextPageAvailable] = useState(false);

  function setTotalPagesCount(total: number) {
    return setPagination({ current: 1, total: total });
  }

  function getResults(config?: StoriesParams) {
    return getStories<T>({
      queryParameters: {
        ...queryParameters,
        filter_query: { ...createIncludesFilterQueryFormat(filters), ...queryParameters?.filter_query },
        ...config,
      },
    });
  }

  function loadMoreResults() {
    if (pagination.current >= pagination.total) return null;
    setIsLoading(true);
    getResults({ page: (pagination.current + 1).toString() }).then(results => {
      if (!results?.data) return null;
      setEntries(prevState => {
        return [...assignType<Array<StoryblokEntry<T>>>(prevState), ...results?.data];
      });
      setIsLoading(false);
    });
    return setPagination(prevState => ({ ...prevState, current: prevState.current + 1 }));
  }

  function createIncludesFilterQueryFormat(filters?: FiltersType | { category?: undefined }) {
    if (!filters) return null;
    return Object.entries(filters).reduce((prevValue, [name, { selected }]) => {
      if (isDataTypeEvents && selected.value === '[all]') return handleEventsAndLiveWebinars(prevValue, name);
      if (isDataTypeResources && selected.value === '[all]') return handleAllResources(prevValue);
      if (isDataTypeResources && selected.value === 'resources/webinar')
        return handleonDemandWebinars(prevValue, name, selected);
      if (selected.value === '[all]') return prevValue;

      if (name === FILTERS.BUSINESS_INITIATIVE || name === FILTERS.CATEGORY)
        return {
          ...prevValue,
          [name]: {
            in_array: selected.value,
          },
        };

      return {
        ...prevValue,
        [name]: {
          in: selected.value,
        },
      };
    }, {});
  }

  useEffect(() => {
    getResults().then(results => {
      if (!results || !language || !filters) return null;
      if (Math.ceil(results.total / results.perPage) !== pagination.total)
        setTotalPagesCount(Math.ceil(results.total / results.perPage));
      if (JSON.stringify(results?.data) !== JSON.stringify(entries)) setEntries(results?.data);
      setStatus('fulfilled');
    });
  }, [filters, language]);

  useEffect(() => {
    setNextPageAvailable(pagination.current < pagination.total);
  }, [pagination]);

  return {
    status,
    entries,
    loadMoreResults,
    nextPageAvailable,
    isLoading,
  };
}
