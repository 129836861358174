import { Datasource } from '@/base/services/storyblok';
import { OptionType } from '@/components/elements/Select/Select.types';

export enum FILTER_DATA_TYPE {
  BLOG = 'blog',
  NEWS = 'news',
  EVENTS = 'events',
  RESOURCES = 'resources',
  CUSTOMERS = 'customers',
  JOBS = 'jobs',
}

export enum FILTERS {
  TYPE = 'type',
  NEWS = 'news_category',
  EVENT = 'event_type',
  CATEGORY = 'category',
  INDUSTRY = 'industry',
  BUSINESS_INITIATIVE = 'business_initiative',
  JOBS_DEPARTMENTS = 'jobs_departments',
  JOBS_LOCATIONS = 'jobs_locations',
  JOBS_TYPES = 'jobs_types',
}

export type FilteringSectionTypes = {
  data_type: FILTER_DATA_TYPE;
  title?: string;
  results_per_page?: number;
};

export type FiltersType = {
  [keyof in string]: { options: Datasource[]; selected: OptionType };
};
